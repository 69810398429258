import React, { Component } from 'react'
import {
    InstantSearch,
    Configure
} from 'react-instantsearch-dom'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Pagination, Header, Footer, Overlay } from '../components/common'
import { Filters, Sidebar, ResultList } from '../components/search'
import { SearchHelper } from '../helpers'

import '../assets/styles/app.scss'
import { SECTIONS } from '../utils/url'
import { addHeadScript } from '../utils/import-embed-script'
import { defaultFacebookPixel, safekidsFacebookPixel } from '../components/common/FacekookPixel'

const HITS_PER_PAGE = 20 // How many results we show per page
const PAGINATION_SIZE_MOBILE = 5 // How many pages we show in the footer
const PAGINATION_SIZE_DESKTOP = 10 // How many pages we show in the footer

class SearchPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            page: 1,
            paginationSize: PAGINATION_SIZE_MOBILE
        }
    }

    componentDidMount() {
        addHeadScript(defaultFacebookPixel)
        const queryParams = SearchHelper.queryParamsToObject(this.props.location.search)
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        this.setState({
            isMobile,
            ...SearchHelper.deserializeSearchState(queryParams),
            paginationSize: isMobile ? PAGINATION_SIZE_MOBILE : PAGINATION_SIZE_DESKTOP
        })
    }

    render() {
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <div className={`app-root advanced-search`}>
                <Helmet title="Starship" />
                <Header activeSection={SECTIONS.VISITOR.key} inferSearchSection={false} />
                <main className={`wrapper has-sidebar`}>
                    <Sidebar />
                    <div className={'content'}>
                        <InstantSearch
                            appId={process.env.GATSBY_ALGOLIA_APP_ID}
                            apiKey={process.env.GATSBY_ALGOLIA_SEARCH_KEY}
                            indexName="starship"
                            searchState={this.state}
                            onSearchStateChange={searchState => window.location.href = SearchHelper.serializeSearchState(searchState)}
                        >
                            <Configure
                                facetingAfterDistinct={true}
                                hitsPerPage={HITS_PER_PAGE}
                                analytics={true}
                            />
                            <Filters />
                            <h4>Results</h4>
                            <ResultList />
                            <Pagination size={this.state.paginationSize} />
                        </InstantSearch>
                    </div>
                </main>
                <Footer activeSection={SECTIONS.VISITOR.key} sitemap={sitemap} />
                <Overlay />
            </div>
        )
    }
}

export const pageQuery = graphql`
query getSiteMapSearch {
  allContentfulWidgetSitemap {
      edges {
          node {
              contentful_id
              userSection
              title
              slug
              links {
                  __typename
                  ... on ContentfulPageCustom {
                      slug
                      title
                      contentful_id
                      userSection
                  }
                  ... on ContentfulPageDirectoryOfServices {
                      id
                      title
                  }
                  ... on ContentfulPageDonation {
                      slug
                      title
                      contentful_id
                  }
                  ... on ContentfulPageGeneric {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGroup {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGuideline {
                      title
                      slug
                  }
                  ... on ContentfulPageWidgets {
                      title
                      slug
                      userSection
                  }
              }
          }
      }
  }
}`

export default SearchPage